import Vue from "vue"
import VueRouter, {RouteConfig} from "vue-router"
import HomeView from "../views/HomeView.vue"
import FavoritesView from "../views/FavoritesView.vue"
import {dateToIsoDateString} from "@/helper";
import "vue-router";
import DayJS from "@/dayJs";
import DayJs from "@/dayJs";

export {}
declare module "vue-router" {
    interface RouteMeta {
        title: string
        showTitle: boolean
        skipEnabled?: boolean
    }
}

Vue.use(VueRouter)

export const skipEnabled = () => false

const META = {
    about: {
        title: "Hi :D",
        showTitle: true,
        skipEnabled: skipEnabled(),
    },
    favorites: {
        title: "Favoriten",
        showTitle: true,
        skipEnabled: skipEnabled(),
    },
    default: {
        skipEnabled: skipEnabled(),
        title: "",
        showTitle: false,
    },
}

const redirect = (to: any) => ({
    path: `/date/${dateToIsoDateString()}`,
})

const redirectFaves = (to: any) => ({
    path: "/favorites",
    replace: true,
})

const routes: Array<RouteConfig> = [
    {
        path: "/favorites",
        name: "Favoriten",
        component: FavoritesView,
        meta: META.favorites,
    },
    {
        path: "/f",
        redirect: redirectFaves,
        meta: META.favorites,
    },
    {
        path: "/bookmarks",
        name: "bookmarks",
        redirect: redirectFaves,
        meta: META.favorites,
    },
    {
        path: "/b",
        redirect: redirectFaves,
        meta: META.favorites,
    },

    {
        path: "/date/:date",
        name: "by-date",
        component: HomeView,
        beforeEnter: (to, from, next) => {
            if (META.default?.skipEnabled) return next();

            const day = DayJS(to.params.date)
            const today = DayJS()

            if (day.isAfter(today)) {
                return next({
                    path: "/",
                    replace: true,
                })
            }
            if (day.isBefore(DayJs("2022-01-01"))) {
                return next({
                    path: "/date/2022-01-01",
                    replace: true,
                })
            }
            return next();
        },
        meta: META.default,
    },
    {
        path: "/d/:date",
        redirect: to => ({
            path: "/date/:date",
            params: to.params,
            replace: true,
        }),
        meta: META.default,
    },
    {
        path: "/current",
        name: "current",
        redirect,
        meta: META.default,
    },
    {
        path: "/date",
        name: "date",
        redirect,
        meta: META.default,
    },
    {
        path: "/",
        name: "home",
        component: HomeView,
        meta: META.default,
    },
    {
        path: "*",
        name: "defaulting",
        redirect: to => ({
            path: "/",
            replace: true,
        }),
        meta: META.default,
    },

    {
        path: "/about",
        name: "Info",
        component: () => import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
        meta: META.about,
    },
]

const router = new VueRouter({
    mode: "history",
    routes,
})

export default router
