
import {Component, Vue} from "vue-property-decorator";
import {copyCurrentVerse, hasHighlightedText} from "@/helper";
import IconButton from "@/components/IconButton.vue";

@Component({
  components: {IconButton},
})
export default class BottomNav extends Vue {

  clickTimer: number | null = null
  animated = false

  copied = false;

  mounted() {
    window.addEventListener("keydown", this.initKeydownListeners)
  }

  beforeDestroy() {
    window.removeEventListener("keydown", this.initKeydownListeners)
  }

  copyVerses() {
    copyCurrentVerse(this.$store.getters.versesString)
        .then(this.setCopied);
  }

  setCopied() {
    this.copied = true
    setTimeout(() => {
      this.copied = false
    }, 3000)
  }

  get to(): string {
    return this.isFavoritesView ? "/" : "/favorites"
  }

  get isFavoritesView(): boolean {
    return this.$route.fullPath === "/favorites"
  }

  get isAboutPage(): boolean {
    return this.$route.fullPath === "/about"
  }

  async initKeydownListeners(event: KeyboardEvent) {
    if (event.repeat) return
    if (this.$route.fullPath === "/about") return
    switch (event.key.toLowerCase()) {
      case "c":
        if (hasHighlightedText()) return
        await copyCurrentVerse(this.$store.getters.versesString)
            .then(this.setCopied)
        return
    }
  }

  clearTimer() {
    if (this.clickTimer) {
      clearTimeout(this.clickTimer)
    }
  }

  animatedTurn() {
    this.animated = true
    setTimeout(() => {
      this.animated = false
    }, 1000)
  }

  toggleColorMode() {
    this.clearTimer()
    this.clickTimer = setTimeout(async () => {
      this.animatedTurn();
      await this.themeService.toggleMode();
      this.clearTimer()
    }, 200)
  }

  toggleSuperDark() {
    this.clearTimer()
    this.themeService.toggleMode("super-dark")
    this.animatedTurn();
  }

  get colorModeIcon() {
    if (this.$store.getters.isSuperDarkMode) {
      return "brightness-minus"
    }
    return this.$store.getters.isDarkMode ? "bright" : "brightness-half"
  }

}
