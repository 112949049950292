import HasStore from "@/services/HasStore";
import IService from "@/services/IService";
import {ColorMode} from "@/app-vue";

export default class ThemeService extends HasStore implements IService {

    public static readonly DARK_MODE_QUERY = "(prefers-color-scheme: dark)"

    constructor({store}: any) {
        super()
        this.store = store
    }

    init(): void {
        const matches = this.getMatches()
        this.onChange(matches ? "dark" : "")

        window.matchMedia(ThemeService.DARK_MODE_QUERY)
            .addEventListener("change", this.listener);

        console.debug("[ThemeService]", "Initialized ThemeService")
    }

    destroy(): void {
        window.matchMedia(ThemeService.DARK_MODE_QUERY)
            .removeEventListener("change", this.listener);
    }

    private listener(event: MediaQueryListEvent) {
        this.onChange(event.matches ? "dark" : "")
    }

    public onChange(mode: ColorMode) {
        this.store.dispatch("toogleColorMode", {
            colorMode: mode,
        })
        this.setColorValuesOnChange(mode)
        console.debug("[ThemeService]", "Changed to mode:", mode)
    }

    public setColorValuesOnChange(mode: ColorMode) {
        const lightColor = "#e8e8e8"
        const darkColor = "#313131"
        const superDarkColor = "#000000"

        const setContent = (color: string) => {
            document.querySelector("meta[name=\"theme-color\"]")
                ?.setAttribute("content", color);
            document.querySelector("meta[name=\"apple-mobile-web-app-status-bar-style\"]")
                ?.setAttribute("content", color);
            document.querySelector("meta[name=\"msapplication-TileColor\"]")
                ?.setAttribute("content", color);
        }

        switch (mode) {
            case "dark":
                setContent(darkColor)
                break
            case "super-dark":
                setContent(superDarkColor)
                break
            default:
                setContent(lightColor)
        }
    }

    public getMatches() {
        return window.matchMedia(ThemeService.DARK_MODE_QUERY).matches;
    }

    public toggleMode(mode: ColorMode | undefined = undefined) {
        const currentMode = this.store.state.colorMode as ColorMode
        if (mode !== undefined && currentMode !== mode) {
            this.onChange(mode)
            return
        }
        switch (currentMode) {
            case "dark":
                this.onChange("")
                break
            default:
                this.onChange("dark")
        }
    }
}
