
import {Component, Vue} from "vue-property-decorator";
import BaseView from "@/views/BaseView.vue";
import {Losung} from "@/types";
import DayJS from "@/dayJs";

@Component({
  components: {BaseView},
})
export default class FavoritesView extends Vue {

  unstarred: string[] = []

  get favorites() {
    return this.storage.favorites.reverse()
        .filter(r => !!r)
  }

  isUnstarred(date: string): boolean {
    return this.unstarred.includes(date)
  }

  toggle(date: string) {
    if (this.isUnstarred(date)) {
      this.unstarred = this.unstarred.filter(item => date !== item)
      this.storage.addDateAsFavorite(date)
      return
    }
    this.unstarred.push(date)
    this.storage.removeDateFromFavorites(date)
  }

  verseForDate(date: string): Losung {
    return this.$store.getters.losungForDate(date)
  }

  excerpt(date: string) {
    const losung = this.verseForDate(date);
    return `${losung.book.book_de} ${losung.book.chapter}, ${losung.book.verses}`
  }

  localeDate(date: string) {
    return DayJS(date).toDate().toLocaleDateString()
  }
}
