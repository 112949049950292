
import {Component, Vue} from "vue-property-decorator";
import IconButton from "@/components/IconButton.vue";

@Component({
  components: {IconButton},
})
export default class BottomNavVerses extends Vue {

  private HEBREW_LETTER_SIMPLE = "ת";
  private HEBREW_LETTER_COMPLEX = "תָּ֕";

  toggleVocalization() {
    this.$store.commit("toggleSimplified")
  }

}
