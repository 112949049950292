import {LosungByYear, YearLosung} from "@/types";
import LosungService from "@/services/LosungService";
import IService from "@/services/IService";

export default class CacheService implements IService {

    public readonly KEY2022: string = `talosung-data_2022`
    public readonly KEY2023: string = `talosung-data_2023`
    public readonly KEY2024: string = `talosung-data_2024`
    private KEYS: { [key: string]: string } = {
        "2022": this.KEY2022,
        "2023": this.KEY2023,
        "2024": this.KEY2024,
    }

    private VERSION_KEY: string = "talosung-data_version"

    private localStorage: Storage
    private losungService: LosungService

    static readonly instance = new CacheService();

    constructor() {
        this.localStorage = window.localStorage;
        this.losungService = new LosungService();
    }

    public init(): void {
    }

    public destroy(): void {
    }

    public saveTanach = (tanach: YearLosung, key: string) => {
        this.localStorage.setItem(key, JSON.stringify(tanach))
    }

    private getLosungen = async (year: number): Promise<YearLosung> => {
        const yearKey = this.KEYS[year]
        const cacheEntry = this.localStorage.getItem(yearKey);
        if (!cacheEntry || !cacheEntry.trim()) {
            const tanach = await this.losungService.getTanach(year);
            this.saveTanach(tanach, yearKey);
            console.debug("[CacheService]", "Fetched and cached files of year", year)
            return tanach
        }
        return JSON.parse(cacheEntry);
    }

    public getLosungenForYears = async (): Promise<LosungByYear> => {
        let tanachs: LosungByYear = {}
        const years: any = Object.keys(this.KEYS)
        for (let i = 0; i < years.length; i++) {
            const year = years[i]
            const losung = await this.getLosungen(year)
            tanachs[year] = losung
        }
        this.localStorage.setItem(this.VERSION_KEY, `${this.getCurrentVersion()}`)
        return tanachs
    }

    public clear = () => {
        [...this.keys(), this.VERSION_KEY].forEach(key => {
            this.localStorage.removeItem(key)
        })
        console.debug("[CacheService]", "Cleared cache.")
    }

    public keys(): string[] {
        return Object.values(this.KEYS).sort()
    }

    public getSavedVersion(): string {
        return this.localStorage.getItem(this.VERSION_KEY) || ""
    }

    public getCurrentVersion(): string {
        return process.env.VUE_APP_VERSION
    }

    public isCurrentVersion(): boolean {
        return !this.isOlderVersion() && this.getSavedVersion() === this.getCurrentVersion()
    }

    public isOlderVersion(): boolean {
        return !!this.getSavedVersion() && this.getSavedVersion() < this.getCurrentVersion()
    }

}
