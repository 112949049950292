
import {Component, Vue} from 'vue-property-decorator';
import {copyCurrentVerse} from "@/helper";

@Component
export default class CopyIcon extends Vue {

  copied = false;

  copyVerses() {
    copyCurrentVerse(this.$store.getters.versesString)
        .then(() => {
          this.copied = true
          setTimeout(() => {
            this.copied = false
          }, 3000)
        });
  }
}
