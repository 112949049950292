import * as localforage from "localforage";
import HasStore from "@/services/HasStore";
import {skipEnabled} from "@/router";
import DayJs from "@/dayJs";
import IService from "@/services/IService";

export default class StorageService extends HasStore implements IService {

    private static readonly FAVS_STORE = "TalosungFavoritesStore"
    private _favorites!: string[]
    private readonly storage!: LocalForage;

    private ready: boolean = false

    static readonly instance = new StorageService();

    constructor() {
        super()
        this.storage = localforage.createInstance({
            name: "favorites",
            version: 1,
            storeName: "FAVS_STORE",
        })
        this.storage.ready()
            .then(async () => {
                await this.init()
                this.isReady = true
                console.debug("[StorageService]", "Initialized StorageService")
            })
    }

    public async init(): Promise<void> {
        const savedFavorites: string[] | undefined | null = await this.storage.getItem(StorageService.FAVS_STORE)
        this.favorites = savedFavorites || []
        if (!savedFavorites) {
            await this.storage.setItem(StorageService.FAVS_STORE, [])
        }
        console.debug("[StorageService]", await this.storage.getItem(StorageService.FAVS_STORE))
    }

    public destroy(): void {
    }

    public set isReady(ready: boolean) {
        this.ready = ready
        this.store?.commit("setStorageReady", ready)
    }

    public get isReady() {
        return this.ready
    }

    public async addDateAsFavorite(date: string) {
        const newFavorites = [...new Set([...this.favorites, date])].sort()
        await this.storage.removeItem(StorageService.FAVS_STORE)
        this.favorites = await this.storage.setItem(StorageService.FAVS_STORE, newFavorites)
    }

    public async removeDateFromFavorites(date: string) {
        const without = [...this.favorites].filter((value, index) => value !== date)
        const newFavorites = [...new Set(without)].sort()
        await this.storage.removeItem(StorageService.FAVS_STORE)
        this.favorites = await this.storage.setItem(StorageService.FAVS_STORE, newFavorites)
    }

    public async getAllFavorites(): Promise<string[]> {
        const favs = (await this.storage.getItem(StorageService.FAVS_STORE) || []) as string[]
        this.favorites = favs
        return favs
    }

    public isFavorite(date: string): boolean {
        return this.favorites?.includes(date)
    }

    public set favorites(favorites: string[]) {
        this._favorites = favorites
    }

    public get favorites(): string[] {
        if (skipEnabled()) return this._favorites
        const filtered = this._favorites.filter(d => {
            return !DayJs(d).isAfter(DayJs())
        })
        return filtered
    }
}
