
import {Component, Vue, Watch} from "vue-property-decorator";
import {RouterView} from "vue-router";
import LogoLoader from "@/components/LogoLoader.vue";
import BottomNav from "@/components/BottomNav.vue";
import Header from "@/components/header/Header.vue";
import PwaUpdate from "@/mixin/pwa-update";
import RefreshFlag from "@/components/RefreshFlag.vue";
import VerseHeader from "@/components/header/VerseHeader.vue";

@Component({
  components: {
    VerseHeader,
    RefreshFlag,
    Header,
    BottomNav,
    LogoLoader,
    RouterView,
  },
  mixins: [PwaUpdate],
})
export default class App extends Vue {

  unsubscribeActions!: () => void

  beforeCreate() {
    this.storage.setStore(this.$store)
    this.themeService.setStore(this.$store)
    this.themeService.init()
  }

  async beforeMount() {
    await this.$store.dispatch("fetchTanach", {params: this.$route.params})
  }

  mounted() {
    this.unsubscribeActions = this.$store.subscribeAction((action, state) => {
    })
    this.setPageMeta()
  }

  beforeDestroy() {
    this.unsubscribeActions()
    this.themeService.destroy()
  }

  get isReady(): boolean {
    return this.$store.state.storageReady && !!this.$store.getters.currentLosung
  }

  setPageMeta() {
      document.querySelector(`meta[name="description"]`)
          ?.setAttribute("content", `${this.$store.getters.currentVerseTitle} • ${this.$store.getters.selectedLocaleDate.toLocaleDateString("de-DE", {
              year: "numeric", month: "short", day: "numeric",
          })}`)
      const title = `Talosung - ${this.$store.getters.currentVerseTitle}`
      document.title = title
      this.metadataService.setMetadata({
          losung: this.$store.getters.currentLosung,
          url: this.$route.fullPath,
          title: title,
      })
  }

  @Watch("$route.fullPath")
  async onRoutePathChange() {
    await this.$store.dispatch("fetchTanach", {params: this.$route.params})
    this.setPageMeta()
  }
}
