
import {Component, Prop, Vue} from "vue-property-decorator";
import IconButton from "@/components/IconButton.vue";

@Component({
  components: {IconButton},
})
export default class RefreshFlag extends Vue {
  @Prop({default: false}) active!: boolean;

  hide = false

  hideFlag() {
    this.hide = true
  }

  get show(): boolean {
    if (this.hide) return false
    return this.active
  }
}
