
import {Component, Vue} from 'vue-property-decorator';
import VerseHeader from "@/components/header/VerseHeader.vue";

@Component
export default class Header extends Vue {

  get component(): any {
    return VerseHeader
  }

}
