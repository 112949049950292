
import {Component, Vue} from "vue-property-decorator";

@Component
export default class FixedCopyIcon extends Vue {

  copied = false;

  copyVerses() {
    navigator.clipboard.writeText(this.$store.getters.versesString)
        .then(() => {
          this.copied = true
          setTimeout(() => {
            this.copied = false
          }, 3000)
        });
  }
}
