
import {Component, Prop, Vue} from "vue-property-decorator";
import {copyCurrentVerse, stripPunctuation} from "@/helper";
import IconButton from "@/components/IconButton.vue";

@Component({
  components: {IconButton},
})
export default class VerseDisplay extends Vue {
  @Prop({default: "de"}) private lang!: "de" | "de2000" | "he" | "en";
  @Prop() private book!: string;
  @Prop() private verseText!: string | string[];

  get isGermanEBU(): boolean {
    return this.lang === "de" && typeof this.verseText === "string";
  }

  get isHebrew(): boolean {
    return !!this.lang && this.lang === "he"
  }

  get verseAsArray(): string[] {
    return this.isGermanEBU ? [this.verseText as string] : this.verseText as string[];
  }

  getLine(line: string): string {
    if (this.isHebrew && this.$store.state.simplified) return this.devocalized(line)
    return line
  }

  devocalized(words: string) {
    return stripPunctuation(words)
  }

  async copyVerse() {
    await copyCurrentVerse(`${this.$store.getters.currentVerseTitle}
${this.verseAsArray.map(this.getLine).join("\n")}

https://talosung.de/date/${this.$store.state.selectedDate}`)
  }
}
