
import {Component, Vue} from "vue-property-decorator";
import {Losung} from "@/types";
import VerseDisplay from "@/components/VerseDisplay.vue";
import CopyIcon from "@/components/CopyIcon.vue";
import BaseView from "@/views/BaseView.vue";
import FixedCopyIcon from "@/components/FixedCopyIcon.vue";
import BottomNavVerses from "@/components/BottomNavVerses.vue";

@Component({
  components: {
    BottomNavVerses,
    FixedCopyIcon,
    BaseView,
    CopyIcon,
    VerseDisplay,
  },
})
export default class HomeView extends Vue {

  get currentLosung(): Losung {
    if (this.$route.name === "by-date") {
      return this.$store.getters.losungForSelectedDate
    }
    return this.$store.getters.currentLosung
  }

}
