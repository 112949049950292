import {YearLosung} from "@/types";
import IService from "@/services/IService";

export default class LosungService implements IService {
    public init(): void {
    }

    public destroy(): void {
    }

    public getTanach = async (year: number = 2022, version: number = 0): Promise<YearLosung> => {
        const baseUrl = process.env.BASE_URL;
        const versionSuffix = version > 1 ? `_v${version}` : ""
        return await fetch(`${baseUrl}losung-tanach-${year}${versionSuffix}.json`)
            .then(async res => {
                return res.json();
            });
    }
}
