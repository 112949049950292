
import {Component, Prop, Vue} from "vue-property-decorator"

@Component
export default class IconButton extends Vue {
  @Prop({default: "copy-alt"}) icon!: string
  @Prop({default: 25}) width!: number
  @Prop({default: undefined}) iconStyle!: "solid" | "thin" | "monochrome" | undefined
  @Prop({default: false}) link!: boolean
  @Prop({default: ""}) to!: string
  @Prop({default: false}) custom!: boolean

  @Prop({default: false}) active!: boolean
  @Prop({default: false}) padded!: boolean

  get isPadded(): boolean {
    return this.padded
  }
}
