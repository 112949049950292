
import {Component, Vue, Watch} from "vue-property-decorator";
import dayjs from "dayjs";
import {dateToIsoDateString} from "@/helper";
import IconButton from "@/components/IconButton.vue";
import DayJS from "@/dayJs";

const EARLIEST_DATE = "2022-01-01"

@Component({
  methods: {DayJS, dayjs},
  components: {IconButton},
})
export default class HeaderDateControl extends Vue {

  refreshKey = -1

  mounted() {
    window.addEventListener("keydown", this.initKeydownListeners)
  }

  beforeDestroy() {
    window.removeEventListener("keydown", this.initKeydownListeners)
  }

  async initKeydownListeners(event: KeyboardEvent) {
    if (event.repeat) return
    if (this.$route.fullPath === "/about") return
    switch (event.key.toLowerCase()) {
      case "b":
      case "f":
      case "s":
        await this.$router.push("/favorites")
        return
      case "a":
        await this.$router.push("/about")
        return
      case "t":
        await this.$router.push("/")
        return
      case "arrowright":
        if (this.hasNext) await this.$router.push(this.toNext)
        return
      case "arrowleft":
        if (this.hasPrevious) await this.$router.push(this.toPrevious)
        return
    }
  }

  get previousDate() {
    return this.addDays(this.getDateFromCurrentRoute(), -1)
  }

  get hasPrevious() {
    return !DayJS(this.dateString).isBefore(DayJS(EARLIEST_DATE));
  }

  get toPrevious() {
    if (!this.hasPrevious) {
      return `/date/${EARLIEST_DATE}`
    }
    return `/date/${this.previousDate}`
  }

  get nextDate() {
    return this.addDays(this.getDateFromCurrentRoute(), 1)
  }

  get hasNext() {
    if (!!this.$route.meta?.skipEnabled) {
      return true
    }
    return !["/about", "/favorites"].includes(this.$route.fullPath)
        && !this.isCurrent
        && !this.$route.path.endsWith(dateToIsoDateString())
        && !DayJS(this.dateString).isAfter(DayJS());
  }

  get toNext() {
    if (this.$route.meta?.skipEnabled) return `/date/${this.nextDate}`
    if (this.isCurrent) return "/"
    if (!this.hasNext) {
      return this.$route.fullPath
    }
    return `/date/${this.nextDate}`
  }

  get isCurrent(): boolean {
    return this.isHome || this.getDateFromCurrentRoute() === dateToIsoDateString()
  }

  get dateString() {
    return DayJS(this.$store.state.selectedDate).toDate().toLocaleDateString()
  }

  get isHome(): boolean {
    this.refreshKey;
    return this.$router.currentRoute.path === "/"
  }

  getDateFromCurrentRoute(): string {
    if (this.isHome) {
      return dateToIsoDateString()
    }
    return this.$router.currentRoute.params.date
  }

  addDays(current: string, days: number) {
    const date = DayJS(current)
    const UNIT = "day"
    return dateToIsoDateString(
        days > 0
            ? date.add(days, UNIT)
            : date.subtract(Math.abs(days), UNIT),
    )
  }

  get showIcons(): boolean {
    // return this.$route.fullPath !== "/favorites"
    return true
  }

  @Watch("$route.fullPath")
  onRoutePathChange() {
    this.refreshKey = this.refreshKey + 1;
  }

}
