import Vue from "vue"
import Vuex from "vuex"
import CacheService from "@/services/CacheService";
import {Losung, LosungByYear, YearLosung} from "@/types";
import {dateToIsoDateString} from "@/helper";
import StorageService from "@/services/StorageService";
import {skipEnabled} from "@/router";
import DayJS from "@/dayJs";
import DayJs from "@/dayJs";
import {ColorMode} from "@/app-vue";

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        years: {} as LosungByYear,
        colorMode: "" as ColorMode,
        storageReady: false,
        selectedDate: new Date().toDateString(),
        favorites: "[]",
        simplified: false,
    },
    getters: {
        isInit: (state, getters): boolean => !!getters.currentLosung,
        currentLosung: (state, getters): Losung | undefined => {
            if (!getters.cacheReady) return undefined
            return (getters.selectedYear as unknown as YearLosung)[state.selectedDate];
        },
        isDarkMode: (state): boolean => state.colorMode === "dark",
        isSuperDarkMode: (state): boolean => state.colorMode == "super-dark",

        losungForSelectedDate: (state, getters): Losung | undefined => {
            const yearLosung = getters.selectedYear
            return !!yearLosung ? yearLosung[state.selectedDate] : undefined;
        },
        losungForDate: (state) => (date: string) => {
            // if (!getters.cacheReady) return undefined
            const selectedYear = getDate(date)
            return state.years[selectedYear.getFullYear()][date]
        },

        selectedLocaleDate: (state): Date => getDate(state.selectedDate),
        selectedLocaleDateString: (state): string => getDate(state.selectedDate).toLocaleDateString(),

        selectedYear: (state, getters): YearLosung | undefined => {
            if (!getters.cacheReady) return undefined
            const selectedYear = getDate(state.selectedDate)
            return state.years[selectedYear.getFullYear()]
        },
        currentVerseTitle: (state, {losungForSelectedDate: losung}): string => `${losung.book.book_de} ${losung.book.chapter}, ${losung.book.verses}`,
        versesString: (state, getters): string => {
            const losung: Losung = getters.losungForSelectedDate
            const german = losung.text.german.trim()
            const german2000 = losung.text.german_sch2000.join("\n").trim()
            const hebrew = losung.text.hebrew.join("\n")
            return `${losung.book.book_de} ${losung.book.chapter}, ${losung.book.verses}

${german}
--
${hebrew}
--
${german2000} (SCH2000)

https://talosung.de/date/${state.selectedDate}`
        },
        cacheReady: (state): boolean => !!state.years && Object.keys(state.years).length > 0,
    },
    mutations: {
        setColorMode: (state, colorMode: ColorMode) => {
            return state.colorMode = colorMode;
        },
        setStorageReady: (state, ready: boolean) => state.storageReady = ready,
        setSelectedDate: (state, date: string) => state.selectedDate = date,
        setFavorites: (state, favorites: string) => state.favorites = favorites,
        toggleSimplified: (state) => state.simplified = !state.simplified,

        setYears: (state, year) => state.years = year,
    },
    actions: {
        fetchTanach: async ({commit, dispatch}, {params}) => {
            const cacheService = CacheService.instance
            const losungen = await cacheService.getLosungenForYears()
            commit("setYears", losungen)
            await dispatch("updateLosung", {params})
        },
        updateLosung: async ({commit, state, getters}, {params}) => {
            commit("setSelectedDate", getDateString(params?.date || ""))
        },
        addFavorite: async ({commit}, date: string) => {
            const storage = StorageService.instance
            await storage.addDateAsFavorite(date)
            const faves = await storage.getAllFavorites()
            commit("setFavorites", JSON.stringify(faves))
        },
        removeFavorite: async ({commit}, date: string) => {
            const storage = StorageService.instance
            await storage.removeDateFromFavorites(date)
            const faves = await storage.getAllFavorites()
            commit("setFavorites", JSON.stringify(faves))
        },
        toogleColorMode: ({commit, state}, {colorMode}) => {
            const bodyClasses = document.documentElement;
            const colorModeAttr = "color-mode"
            // colorMode = "super-dark"
            commit("setColorMode", colorMode)
            bodyClasses.setAttribute(colorModeAttr, colorMode)
        },
    },
    modules: {},
})

const getDate = (dateParam: string): Date => {
    const defaultDateObj = new Date();
    if (!dateParam) return defaultDateObj;

    if (!skipEnabled()) {
        const earliestDate = "2022-01-01";
        const dayjsDate = DayJS(dateParam);

        if (dayjsDate.toDate() > defaultDateObj) return defaultDateObj;
        if (dayjsDate.toDate() <= DayJs(earliestDate).toDate()) return DayJS(earliestDate).toDate();
    }

    const dayjsToDateDate = DayJS(dateParam).toDate();
    return dayjsToDateDate;
}

const getDateString = (dateParam: string): string => {
    return dateToIsoDateString(getDate(dateParam));
}
